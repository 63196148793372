#container {
  position: relative;
  z-index: 10;
  max-width: 880px;
  margin: 0 auto;
  padding: 15px;
}
/*
    Radio Buttons
  */
@media (min-width: 600px) {
  .wrapper {
    padding: 0 8rem;
  }
}

body {
  background: #fff;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
.table_nps {
  margin: 25px 0 30px;
}

.table_nps > .nps {
  margin: auto;
  height: 50px;
}

.table_nps > .nps > input[id^="nps-"] {
  display: none;
}

.table_nps > .nps > input[id^="nps-"] + label {
  float: left;
  width: 9.090909091%;
  height: 60px;
  line-height: 60px;
  background: white;
  transform: scale(1);
  text-align: center;
  font-size: 35px;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sombra {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table_nps > .nps > input[id^="nps-"] + label:active,
.table_nps > .nps > input[id^="nps-"] + label.touched {
  background: #1368a9;
  color: white;
  transform: scale(1.3);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.table_nps > .nps > input[id^="nps-"]:checked + label:hover {
  background: #1368a9;
  color: white;
  transform: scale(1);
}

.table_nps > .nps > input[id^="nps-"]:checked + label {
  background: #1368a9;
  color: white;
}

@media (max-width: 880px) {
  .table_nps > .nps > input[id^="nps-"] + label {
    font-size: 30px;
    height: 60px;
    line-height: 60px;
  }
  #container {
    padding: 10px;
  }
}

@media (max-width: 520px) {
  .table_nps > .nps > input[id^="nps-"] + label {
    font-size: 25px;
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 475px) {
  .table_nps > .nps > input[id^="nps-"] + label {
    font-size: 20px;
    height: 35px;
    line-height: 35px;
  }
}

@media (max-width: 350px) {
  .table_nps > .nps > input[id^="nps-"] + label {
    font-size: 10px;
    height: 25px;
    line-height: 25px;
  }
}
@media (max-width: 250px) {
  .table_nps > .nps > input[id^="nps-"] + label {
    font-size: 8px;
    height: 20px;
    line-height: 20px;
  }
}

.nps > label:hover {
  background-color: #1368a9 !important;
  color: #fff;
}

.btn {
  box-shadow: 0px 5px 8px -1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 5px 8px -1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 5px 8px -1px rgba(0, 0, 0, 0.55);
}

.puntaje {
  width: 46px;
  height: 46px;
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  border-top: 2px solid #dadada;
  border-bottom: 2px solid #dadada;
  border-right: 2px solid #dadada;
}
.puntaje:nth-child(1) {
  border-left: 2px solid #dadada;
}
@media (max-width: 768px) {
  .mobile {
    flex-basis: calc(15%);
  }
  .puntaje:nth-child(7) {
    border-radius: 5px 0 0 5px;
    border-left: 2px solid #dadada;
  }
  .puntaje:nth-child(6) {
    border-radius: 0 5px 5px 0;
  }
}
.puntaje:hover,
.activo {
  background-color: #e9f1f8;
  border: 2px solid #2a75d5 !important;
}
.btn-survey {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
